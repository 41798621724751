<template>
  <div id="simpleForm">
    <el-form ref="simpleForm" v-bind="$attrs" :model="model" :inline="inline">
      <el-form-item v-if="$slots.info">
        <slot name="info" />
      </el-form-item>
      <el-form-item v-for="(item,index) in showItem" v-show="!item.notShow" :key="index" :label="inline?'':(Object.prototype.toString.call(item.name) === '[object Function]') ?item.name():item.name" :prop="item.param">
        <slot v-if="check(item)" :name="item.slotName" :row="item" currentType="currentType">
          <simple-form-wrapper :show-form="item" :data="model" :inline="inline" :current-type="currentType" />
        </slot>
        <simple-form-wrapper v-else :show-form="item" :data="model" :inline="inline" :current-type="currentType" />
      </el-form-item>
      <el-form-item v-if="$slots.last">
        <slot name="last" />
      </el-form-item>
      <slot />
    </el-form>
  </div>
</template>
<script>
import SimpleFormWrapper from './SimpleFormWrapper'
export default {
  components: { SimpleFormWrapper },
  props: {
    showForm: { type: Array, required: false, default: () => {} },
    model: { type: Object, required: true },
    currentType: { type: String, default: 'add', required: false },
    inline: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      showItem: null
    }
  },
  mounted() {
    this.showItem = this.showForm.map((item, index) => {
      return Object.assign({}, item)
    })
    const w = () => {
      this.showForm.forEach((item, index) => {
        for (const itemKey in item) {
          if (Object.prototype.toString.call(item[itemKey]) === '[object Function]' && itemKey !== 'formatter') {
            this.showItem[index][itemKey] = this.showForm[index][itemKey](this.currentType, this.model)
            this.$watch(() => this.showForm[index][itemKey](this.currentType, this.model), () => {
              this.showItem[index][itemKey] = this.showForm[index][itemKey](this.currentType, this.model)
            })
          }
        }
      })
    }
    w()
  },
  methods: {
    check(item) {
      if (item.type === 'slot') {
        return true
      } else {
        return false
      }
    }
  },
}
</script>
