<script>
export default {
  name: 'SimpleFormWrapper',
  props: {
    data: { type: Object, required: false, default: () => {} },
    showForm: { type: Object, default: () => {}, required: false },
    inline: { type: Boolean, default: false, required: false },
    // 当前类型 add search delete update
    currentType: {
      type: String,
      default: 'add',
      required: false,
      validator: (value) => {
        return ['add', 'search', 'select', 'delete', 'update'].includes(value)
      }
    },
  },
  render(createElement, context) {
    let item = null
    const myBind = {
      attrs: {
        ...this.showForm,
        ...this.$attrs
      },
      on: {
        ...this.$listeners,
      }
    }
    this.showForm.className = this.showForm.className ? this.showForm.className : []
    const slots = this.showForm.slots?.map(item => {
      return <template slot={item.name}>{this.showForm.template}</template>
    })
    switch (this.showForm.type) {
    case 'slot':
      item = this.showForm.content
      break
    case 'transfer' :
      if (!this.data[this.showForm.param]) {
        this.data[this.showForm.param] = []
      }
      item = <el-transfer {...myBind} vModel={this.data[this.showForm.param] } data={this.showForm.transfer} titles={['可选', '已选']} ></el-transfer>
      break
    case 'select' :
      item = <el-select {...myBind} vModel={this.data[this.showForm.param]} placeholder={this.showForm.name}>
        {this.showForm.option.map(item => {
          return <el-option key={item.value} value={item.value} label={item.label} >{item.content ? item.content : item.label}</el-option>
        })}
      </el-select>
      break
    case 'date' :
      item = <div className="block">
        <el-date-picker {...myBind} vModel={this.data[this.showForm.param]} type={this.showForm.date ? this.showForm.date : 'daterange'} format="yyyy-MM-dd" value-format="yyyy-MM-dd hh:mm:ss"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels/></div>
      break
    case 'number' :
      item = <el-input-number {...myBind} size="mini" v-model={this.data[this.showForm.param]} controls={false}/>
      break
    case 'radio' :
      item = <el-radio-group style="padding: 13px;" {...myBind} v-model={this.data[this.showForm.param]}>
        { this.showForm.radio.map(item => {
          return <el-radio border={item.border} size={item.size} label={item.value}>{item.label}</el-radio>
        })}
      </el-radio-group>
      break
    case 'textarea' :
    default:
      item = <el-input {...myBind} vModel={this.data[this.showForm.param]} autocomplete="on" placeholder={this.showForm.name} show-word-limit={!this.inline}>
        {slots}
      </el-input>
    }
    if (this.inline) {
      return <span class={['help', ...this.showForm.className]}>{item}</span>
    }
    if (this.showForm.help) {
      return <span class={['help', ...this.showForm.className]}>{item}<span class="tip">{<el-tooltip content={this.showForm.help} placement="top"><i class="fa fa-info-circle"></i></el-tooltip>}</span></span>
    } else {
      return <span class={['help', ...this.showForm.className]}>{item}<span class="tip"></span></span>
    }
  }
}
</script>

<style scoped>
.help{
  display: flex;
  justify-content: space-between;
}
.help .tip{
  width: 20px;
  height: auto;
  text-align: center;
  margin: auto 10px;
}
</style>
